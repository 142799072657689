import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import isEmail from 'validator/lib/isEmail'

import styles from './build-your-package.module.scss'
import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import Section from '../../components/Section'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { Intro, IntroTitle, IntroText } from '../../components/Intro'
import usePublicApi from '../../hooks/usePublicApi'
import _ from 'lodash'

const BuildYourPackage = () => {
  const [type, setType] = useState(null)
  const [incCoSec, setIncCoSec] = useState(false)
  const [incPayroll, setIncPayroll] = useState(false)
  const [incCis, setIncCis] = useState(false)
  const [incVat, setIncVat] = useState(false)
  const [turnover, setTurnover] = useState('')
  const [employees, setEmployees] = useState('')
  const [payrollFreq, setPayrollFreq] = useState(null)
  const [name, setName] = useState('')
  const [ready, setReady] = useState(false)
  const [email, setEmail] = useState('')
  const [{ isLoading, success, error }, { sendRequest }] = usePublicApi()

  useEffect(() => {
    if (success) {
      window.scrollTo(0, 0)
    }
  }, [success])

  const onTurnoverChange = e => {
    const { value } = e.target
    const cleanValue = value.replace(/\D/g, '')
    const formattedValue = cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (cleanValue === '') {
      setTurnover('')
    } else {
      setTurnover(formattedValue)
    }
  }

  const onEmployeesChange = e => {
    const { value } = e.target
    const cleanValue = value.replace(/\D/g, '')

    if (cleanValue === '') {
      setEmployees('')
    } else {
      setEmployees(cleanValue)
    }
  }

  const handleSubmit = () => {
    sendRequest({
      method: 'post',
      url: '/marketing/package',
      data: {
        type,
        turnover,
        employees,
        payrollFreq,
        incCoSec,
        incPayroll,
        incCis,
        incVat,
        name,
        email,
      },
    })
  }

  const types = {
    LTD: 'Limited company',
    SE: 'Sole trader',
    PART: 'Partnership',
  }

  const payrollFreqs = {
    MONTHLY: 'Monthly',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    FOURWEEKLY: 'Fourweekly',
    QUARTERLY: 'Quarterly',
    ANNUALLY: 'Annually',
  }

  const getType = key => types[key]
  const getFreq = key => payrollFreqs[key]

  const servicesCn = cn(styles.section, styles.services, {
    [styles.partHide]:
      employees === '' || (employees !== '0' && payrollFreq === null),
  })

  const turnoverCn = cn(styles.section, styles.turnover, {
    [styles.partHide]: type === null,
  })

  const employeesCn = cn(styles.section, styles.employees, {
    [styles.partHide]: turnover === '',
  })

  const payrollFreqCn = cn(styles.section, styles.payrollFreq, {
    [styles.hide]: turnover === '' || employees === '0',
    [styles.partHide]: employees === '',
  })

  const nameCn = cn(styles.section, styles.name, {
    [styles.partHide]: !ready,
  })

  const emailCn = cn(styles.section, styles.email, {
    [styles.partHide]: name === '',
  })

  if (success) {
    return (
      <SiteLayout header="light">
        <SEO
          title="Build your package"
          description="Use our small business pricing tool to build your accounting package and receive an instant quote. Our team are available to help if you need it."
        />
        <TitleContainer>
          <Title>Build your package</Title>
          <TitleIntro>
            Use our tool below to build your small business accounting package.
            We'll email your package to you along with your monthly fee based on
            the information you give us. Our team will then get in touch to see
            if you have any questions that need answering.
          </TitleIntro>
        </TitleContainer>
        <Section color="gray">
          <div className={styles.success}>
            <Intro>
              <IntroTitle>Check your email inbox!</IntroTitle>
              <IntroText>
                We have sent your accounting package and quote to the email
                address you provided us. A member of our team will contact you
                shortly to answer any questions you may have.
              </IntroText>
            </Intro>
            <div className={styles.summary}>
              <h4>Here's a summary of your package</h4>
              <h5>Business information</h5>
              <div className={styles.details}>
                <p>
                  Business type: <strong>{getType(type)}</strong>
                </p>
                <p>
                  Annual turnover: <strong>£{turnover}</strong>
                </p>
                <p>
                  Number of employees: <strong>{employees}</strong>
                </p>
                {employees !== '0' && (
                  <p>
                    Payroll frequency: <strong>{getFreq(payrollFreq)}</strong>
                  </p>
                )}
              </div>
              <h5>Services required</h5>
              <div className={styles.details}>
                <ul className="bullet-list">
                  <li>Year end accounts</li>
                  <li>
                    {type === 'LTD' ? 'Corporation Tax' : 'Self-assessment'}
                  </li>
                  {incPayroll && <li>Payroll & pension</li>}
                  {incCis && <li>CIS</li>}
                  {incVat && <li>VAT</li>}
                  {incCoSec && <li>Company secretarial</li>}
                </ul>
              </div>
              <h5>Contact information</h5>
              <div className={styles.details}>
                <p>
                  Your name: <strong>{name}</strong>
                </p>
                <p>
                  Your email: <strong className={styles.break}>{email}</strong>
                </p>
              </div>
            </div>
          </div>
        </Section>
      </SiteLayout>
    )
  }

  return (
    <SiteLayout header="light">
      <SEO
        title="Build your package"
        description="Use our small business pricing tool to build your accounting package and receive an instant quote. Our team are available to help if you need it."
      />
      <TitleContainer>
        <Title>Build your package</Title>
        <TitleIntro>
          Use our tool below to build your small business accounting package.
          We'll email your package to you along with your monthly fee based on
          the information you give us. Our team will then get in touch to see if
          you have any questions that need answering.
        </TitleIntro>
      </TitleContainer>
      <Section color="gray" className={styles.sectionContainer}>
        <div className={styles.type}>
          <Intro>
            <IntroTitle>What is your business type?</IntroTitle>
            <IntroText>
              If you’re just starting up and your business is not yet
              registered, select the business type you plan to trade as.
            </IntroText>
          </Intro>
          <div className={styles.optionList}>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, {
                  [styles.active]: type === 'SE',
                })}
                onClick={() => setType('SE')}
                type="button"
              >
                <span className={styles.heading}>Sole trader</span>
                <span className={styles.desc}>
                  You are registered as self-employed with HMRC.
                </span>
              </button>
            </div>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, {
                  [styles.active]: type === 'LTD',
                })}
                onClick={() => setType('LTD')}
                type="button"
              >
                <span className={styles.heading}>Limited company</span>
                <span className={styles.desc}>
                  Your business is registered as a company with Companies House.
                </span>
              </button>
            </div>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, {
                  [styles.active]: type === 'PART',
                })}
                onClick={() => setType('PART')}
                type="button"
              >
                <span className={styles.heading}>Partnership</span>
                <span className={styles.desc}>
                  Your business is registered as a partnership with HMRC.
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className={turnoverCn}>
          <Intro>
            <IntroTitle>What is your annual turnover?</IntroTitle>
            <IntroText>
              You can find your annual turnover on your last set of accounts. If
              you've never had a set of accounts or don't have any to hand,
              enter your best guess to the nearest £50,000.
            </IntroText>
          </Intro>
          <input
            id="turnover"
            onChange={onTurnoverChange}
            placeholder="Annual turnover"
            type="text"
            value={turnover}
          />
        </div>
        <div className={employeesCn}>
          <Intro>
            <IntroTitle>How many employees do you have?</IntroTitle>
            <IntroText>
              The number of employees you will have on your next pay run,
              excluding directors/owners. If you don't have any employees, just
              enter '0'. If you have CIS sub-contractors enter them here too.
            </IntroText>
          </Intro>
          <input
            id="employees"
            onChange={onEmployeesChange}
            placeholder="Number of employees"
            type="text"
            value={employees}
          />
        </div>
        <div className={payrollFreqCn}>
          <Intro>
            <IntroTitle>How often do you pay your employees?</IntroTitle>
            <IntroText>
              This is how often you actually pay your employees their wages.
            </IntroText>
          </Intro>
          <select
            id="payrollFreq"
            onChange={e => setPayrollFreq(e.target.value || null)}
            value={payrollFreq || ''}
          >
            <option>Pay frequency</option>
            <option value="MONTHLY">Monthly</option>
            <option value="WEEKLY">Weekly</option>
            <option value="FORTNIGHTLY">Fortnightly</option>
            <option value="FOURWEEKLY">Four weekly</option>
            <option value="QUARTERLY">Quarterly</option>
            <option value="ANNUALLY">Annually</option>
          </select>
        </div>
        <div className={servicesCn}>
          <Intro>
            <IntroTitle>Which services would you like?</IntroTitle>
            <IntroText>
              Choose the services you would like included in your package.
              Accounts and tax returns are always included as standard.
            </IntroText>
          </Intro>
          <div className={styles.optionList}>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, styles.active)}
                type="button"
              >
                <span className={styles.headingNote}>Always included</span>
                <span className={styles.heading}>Year end accounts</span>
                <span className={styles.desc}>
                  Year end accounts prepared by us to meet all accounting
                  standards.
                </span>
              </button>
            </div>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, styles.active)}
                type="button"
              >
                <span className={styles.headingNote}>Always included</span>
                <span className={styles.heading}>
                  {type === 'LTD' ? 'Corporation Tax' : 'Self-assessment'}
                </span>
                <span className={styles.desc}>
                  Tax return prepared by us, claiming all available expenses and
                  reliefs.
                </span>
              </button>
            </div>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, {
                  [styles.active]: incPayroll,
                })}
                onClick={() => setIncPayroll(!incPayroll)}
                type="button"
              >
                <span className={styles.headingNote}>Add-on</span>
                <span className={styles.heading}>Payroll & pension</span>
                <span className={styles.desc}>
                  Taxes and deductions calculated and payslips prepared for your
                  staff.
                </span>
              </button>
            </div>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, {
                  [styles.active]: incVat,
                })}
                onClick={() => setIncVat(!incVat)}
                type="button"
              >
                <span className={styles.headingNote}>Add-on</span>
                <span className={styles.heading}>VAT</span>
                <span className={styles.desc}>
                  VAT returns prepared by us, calculating payments or refunds
                  due.
                </span>
              </button>
            </div>
            <div className={styles.optionContainer}>
              <button
                className={cn(styles.option, {
                  [styles.active]: incCis,
                })}
                onClick={() => setIncCis(!incCis)}
                type="button"
              >
                <span className={styles.headingNote}>Add-on</span>
                <span className={styles.heading}>CIS</span>
                <span className={styles.desc}>
                  CIS returns prepared by us for your construction
                  subcontractors.
                </span>
              </button>
            </div>
            {type === 'LTD' && (
              <div className={styles.optionContainer}>
                <button
                  className={cn(styles.option, {
                    [styles.active]: incCoSec,
                  })}
                  onClick={() => setIncCoSec(!incCoSec)}
                  type="button"
                >
                  <span className={styles.headingNote}>Add-on</span>
                  <span className={styles.heading}>Company secretarial</span>
                  <span className={styles.desc}>
                    Legal filings prepared and submitted to Companies House on
                    your behalf.
                  </span>
                </button>
              </div>
            )}
          </div>
          <div className={styles.finish}>
            <button
              className="btn btn-primary"
              onClick={() => setReady(true)}
              type="button"
            >
              Enter contact details
            </button>
          </div>
        </div>
        <div className={nameCn}>
          <Intro>
            <IntroTitle>What should we call you?</IntroTitle>
            <IntroText>So we know how to say hello...</IntroText>
          </Intro>
          <input
            id="name"
            onChange={e => setName(e.target.value)}
            placeholder="Your full name"
            type="text"
            value={name}
          />
        </div>
        <div className={emailCn}>
          <Intro>
            <IntroTitle>Where should we send your quote?</IntroTitle>
            <IntroText>
              We'll send the package you have built and your monthly fee to this
              email address.
            </IntroText>
          </Intro>
          <input
            id="email"
            onChange={e => setEmail(e.target.value)}
            placeholder="Your email address"
            type="email"
            value={email}
          />
          <div className={styles.finish}>
            <button
              className="btn btn-primary"
              disabled={!isEmail(email) || isLoading}
              onClick={handleSubmit}
              type="button"
            >
              {isLoading
                ? 'Sending...'
                : error
                ? 'Try again'
                : 'Send my package and quote'}
            </button>
            {error && (
              <div className={styles.error}>
                <p>
                  There's was a problem sending your package. Please try again.
                </p>
                <small>
                  Error code: {_.get(error, 'response.status') || '900'}
                </small>
              </div>
            )}
          </div>
        </div>
      </Section>
    </SiteLayout>
  )
}

export default BuildYourPackage
